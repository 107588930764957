import React from 'react';
// import { graphql, useStaticQuery } from "gatsby";

import Layout from '../components/Layout';
// import Blog from '../components/Blog';
import SocialButtons from '../components/SocialButtons';
import profile from '../images/profile.jpg';

// import { useSiteMetadata } from '../hooks/useSiteMetadata';
// const { title, description } = useSiteMetadata();

export default () => (
    <Layout>
        <div className="column is-full ">
            <h1>👋 Hey, Zdeněk here!</h1>
        </div>
        <br />
        <div className="columns row">
            <div className="column is-5 is-offset-1 is-mobile">
                <div className="image is-fullwidth">
                    <img
                        alt="Zdeněk Hásek"
                        src={profile}
                    />
                </div>
            </div>
            <div className="column is-5 is-mobile">
                <SocialButtons />
            </div>
        </div>
        <br />
        {/* <div className="columns">
            <div className="column is-full is-mobile">
                <Blog />
            </div>
        </div> */}
    </Layout>
);
