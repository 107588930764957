import React from 'react';
import { Link } from 'gatsby';
import Icons from './Icons';
import {
    SOCIAL_URL_GITHUB,
    SOCIAL_URL_INSTAGRAM,
    SOCIAL_URL_LINKEDIN,
    SOCIAL_URL_MAIL
} from '../config/cons';

export default () => (
    <div className="socials">
        <div className="social">

            <div className="columns">

                <a
                    href={SOCIAL_URL_MAIL}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-links"
                >
                    <div className="column is-1 ">

                        <Icons icon="faEnvelope" size="3x" />
                    </div>
                </a>
                <div className="column is-10">
                    <b>📍 Based in Prague</b>
                    , Czech republic.

                </div>
            </div>

        </div>

        <div className="social">
            <div className="columns">
                <a
                    href={SOCIAL_URL_GITHUB}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-links"
                >
                    <div className="column is-1 ">

                        <Icons icon="faGithub" size="3x" />

                    </div>
                </a>

                <div className="column is-10">
                    <b>💻 Multi-fields IT professional</b>
                    , work bio
                    {' '}
                    <Link to="/skills">here</Link>
                    .
                </div>
            </div>

        </div>

        <div className="social">
            <div className="columns">
                <a
                    href={SOCIAL_URL_LINKEDIN}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-links"
                >
                    <div className="column is-1 ">

                        <Icons icon="faLinkedin" size="3x" />
                    </div>
                </a>
                <div className="column is-10">
                    <b>⏲ Right now</b>
                    , working as a freelance
                    {' '}
                    <b>DevOps Solution Architect</b>
                    .
                </div>
            </div>

        </div>

        <div className="social">

            <div className="columns">

                <a
                    href={SOCIAL_URL_INSTAGRAM}
                    target="_blank"
                    rel="noreferrer"
                    className="icon-links"
                >
                    <div className="column is-1">
                        <Icons icon="faInstagram" size="3x" />
                    </div>
                </a>

                <div className="column is-10 ">
                    <b>🖤 Big fan of</b>
                    : 🐧 Linux, Ⓜ Minimalism,
                    🌏 Backpacking, 🏍️ Motorbiking, ⛵ Yachting, 📚 Reading and 🍕&🍍.

                </div>
            </div>

        </div>
    </div>

);
